<template>
  <div class="electricDetail">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false"
               @close="closeDio('dioform')">
      <div>
        <div class="contDio">
          <el-form class="contDio" ref="dioform" :model="inform" label-width="100px" size="small">
            <el-row>
              <el-col :span="24">
                <el-form-item label="设备名称:">{{ inform.boardName }}</el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="设备标识:">{{ inform.boardId }}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="告警内容:">{{ inform.alarmContent }}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="任务名称:">{{ inform.alarmTask }}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="任务描述:">{{ inform.taskDescription }}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="视频通道:">{{ inform.videoChannel }}</el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-tabs v-model="tabsActive" @tab-click="tabsClick" type="border-card">
            <el-tab-pane label="告警截图" name="1">
              <el-image
                  :src="getUrlObj(inform.imageUrl1)"
                  style="width:100%; height:100%; object-fit: fill"
                  fit="fill"
                  :preview-src-list="toArr(inform.imageUrl1)">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </el-tab-pane>
            <el-tab-pane label="告警视频" name="2">
              <video controls style="width:100%; height:100%; object-fit: fill">
                <source :src="inform.videoUrl1" type="video/mp4">
              </video>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {apiUrl, globalStr} from '@/assets/js/api'
import qs from 'qs'
import {delChildren, getUUID, formatTime} from '@/utils/utils'

let d_icon1 = require('@/assets/images/device-box.png');
var vm;
export default {
  props: ['dicObj'],
  data() {
    return {
      isDio: false,//弹窗类型
      dioTit: '',//弹窗标题
      dioType: '',//弹窗类型
      dioWidth: '',//弹窗宽度

      tabsActive: '1',//tabs

      //form信息
      inform: {},

    }
  },
  created() {
    vm = this
  },
  filters: {
    //状态过滤器
    statesFilter(val, type) {
      //在线状态
      if (type == '1') {
        let result = vm.dicObj.statusData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
    },
  },
  //方法
  methods: {
    toArr(url){
      let arr = [];
      arr.push(this.getUrlObj(url))
      return arr;
    },
    getUrlObj(url){
      return url+"?_t="+new Date().getTime();
    },
    //初始化
    init(item) {
      console.log(item);
      vm.dioWidth = '840px'
      vm.isDio = true
      vm.dioTit = '详情';
      vm.$nextTick(() => {
        vm.inform = item;
      })
    },
    getIcon() {
      return d_icon1;
    },
    //获取uuid
    getUUID() {
      return getUUID()
    },

    //tabs切换
    tabsClick(tab, event) {

    },

    //关闭弹窗
    closeDio(formEl) {
      vm.inform = {}
      vm.tabsActive = '1'
      vm.isDio = false
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
  }
}
</script>
<style lang='scss'>
.electricDetail {
  .contDio {
    max-height: 500px;
    overflow: auto;

    .el-form-item--small.el-form-item {
      margin-bottom: 0px;
    }

    .el-image {
      width: 100px;
      height: 150px;
    }

    .name {
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
      font-size: 16px;
      color: #fff;
    }
  }
}
</style>